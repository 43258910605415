import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MoreStories = () => {
  return (
    <div>
      {/* <!-- More stories --> */}
      <section className="uk-section uk-section-muted">
        <div className="uk-container">
          <h2 className="uk-text-bold">More stories</h2>
          <div uk-slider="velocity: 5">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-medium">
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            <StaticImage
                              src="../../images/management7.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              transformOptions={{ grayscale: true }}
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            <StaticImage
                              src="../../images/management2.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                            {/* <img data-src="https://unsplash.it/620/350/?random=2" width="620" height="350" uk-img="target: !.uk-slideshow-items" alt="" /> */}
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            {/* <img data-src="https://unsplash.it/620/350/?random=3" width="620" height="350" data-uk-img="target: !.uk-slideshow-items" alt="" /> */}
                            <StaticImage
                              src="../../images/management9.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            {/* <img data-src="https://unsplash.it/620/350/?random=4" width="620" height="350" data-uk-img="target: !.uk-slideshow-items" alt="" /> */}
                            <StaticImage
                              src="../../images/management3.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            {/* <img data-src="https://unsplash.it/620/350/?random=5" width="620" height="350" data-uk-img="target: !.uk-slideshow-items" alt="" /> */}
                            <StaticImage
                              src="../../images/management5.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            {/* <img data-src="https://unsplash.it/620/350/?random=6" width="620" height="350" data-uk-img="target: !.uk-slideshow-items" alt="" /> */}
                            <StaticImage
                              src="../../images/management4.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                  <li>
                    {/* <!-- card --> */}
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/" aria-label="card">
                            {/* <img data-src="https://unsplash.it/620/350/?random=7" width="620" height="350" data-uk-img="target: !.uk-slideshow-items" alt="" /> */}
                            <StaticImage
                              src="../../images/management10.jpg"
                              alt="home-section-one"
                              formats={["AUTO", "WEBP", "AVIF"]}
                              placeholder="blurred"
                              layout="constrained"
                              width={620}
                              height={410}
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <StaticImage
                                className="uk-border-circle"
                                src="../../images/avatar-2.png"
                                alt="home-section-one"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                transformOptions={{ grayscale: true }}
                                layout="constrained"
                                height={40}
                                aspectRatio={4 / 3}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                Author Name
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateTime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <span className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </span>
                          <br />
                          <a
                            className="uk-button uk-button-default uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /card --> */}
                  </li>
                </ul>
              </div>
              <ul className=" uk-dotnav uk-flex-center uk-margin"></ul>
              <div className="uk-hidden@m uk-light">
                <a
                  className="uk-position-center-left uk-position-small"
                  href="/"
                  aria-label="Previous"
                  uk-slidenav-previous="true"
                  uk-slider-item="previous"
                >
                  {null}
                </a>
                <a
                  className="uk-position-center-right uk-position-small"
                  href="/"
                  aria-label="Next"
                  uk-slidenav-next="true"
                  uk-slider-item="next"
                >
                  {null}
                </a>
              </div>
              <div className="uk-visible@m">
                <a
                  className="uk-position-center-left-out uk-position-small"
                  href="/"
                  aria-label="Next"
                  uk-slidenav-previous="true"
                  uk-slider-item="previous"
                >
                  {null}
                </a>
                <a
                  className="uk-position-center-right-out uk-position-small"
                  href="/"
                  aria-label="Previous"
                  uk-slidenav-next="true"
                  uk-slider-item="next"
                >
                  {null}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /More stories --> */}
    </div>
  )
}

export default MoreStories
