import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import MoreStories from "../components/News/moreStories"
import { Hero } from "../containers"

const TestPage = ({ data }) => {
  const image = getImage(
    data.strapiNewsPage.image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <>
      {/* <HeroArticle /> */}
      <Hero image={image} title={data.strapiNewsPage.title} />

      <br />
      <br />

      <div className="uk-container uk-container-small">
        <hr className="uk-margin-remove" />
      </div>

      <section className="uk-section uk-section-small">
        <div id="author-wrap" className="uk-container uk-container-small">
          <div
            className="uk-grid uk-grid-medium uk-flex uk-flex-middle"
            uk-grid="true"
          >
            <div className="uk-width-auto">
              <StaticImage
                className="uk-border-circle"
                src="../images/avatar-2.png"
                alt="home-section-one"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                transformOptions={{ grayscale: true }}
                layout="constrained"
                height={50}
                aspectRatio={4 / 3}
              />
            </div>

            <div className="uk-width-expand">
              <h4 className="uk-margin-remove uk-text-bold">John Article</h4>
              <span className="uk-text-small uk-text-muted">
                Ut enim ad minim veniam, quis nostrud exercitation .
              </span>
            </div>
            <div className="uk-width-auto">
              <div className="uk-inline">
                <a
                  href="/"
                  className="uk-icon-button"
                  aria-label="Icon verticle"
                  uk-icon="icon:more-vertical"
                >
                  {null}
                </a>
                <div uk-dropdown="mode:hover; pos: bottom-right; boundary:#author-wrap">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li className="uk-nav-header">Actions</li>
                    <li>
                      <a href="/">Rate this author</a>
                    </li>
                    <li>
                      <a href="/">Follow this author</a>
                    </li>
                    <li>
                      <a href="/">Bookmark</a>
                    </li>
                    <li>
                      <a href="/">View more articles</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--/AUTHOR--> */}
      <div className="uk-container uk-container-small">
        <hr className="uk-margin-remove" />
      </div>

      {/* <!--ARTICLE--> */}
      <section className="uk-section uk-article">
        <div className="uk-container uk-container-small">
          <h2 className="uk-text-bold uk-h1 uk-margin-remove-adjacent uk-margin-remove-top">
            Fusce facilisis tempus magna ac dignissim.
          </h2>
          <p className="uk-article-meta">
            Written on 12 April 2012. Posted in{" "}
            <a href="/" aria-label="Blog">
              Blog
            </a>{" "}
            | <span uk-icon="icon: future"></span> Takes 7 min reading.
          </p>

          <p className="uk-text-lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip.
          </p>

          <p>
            UPDATE 24th October 15.10 BST — Vivamus sed consequat urna. Fusce
            vitae urna sed ante placerat iaculis. Suspendisse potenti.
            Pellentesque quis fringilla libero. In hac habitasse platea
            dictumst.
          </p>
        </div>

        {/* <!-- large image --> */}
        <div className="uk-container uk-section">
          <div
            className="uk-position-relative uk-dark"
            uk-slideshow="ratio: 7:3; animation: push; min-height: 270; velocity: 3"
          >
            <ul className="uk-slideshow-items">
              <li>
                <StaticImage
                  src="../images/Leadership-1.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={1200}
                  height={550}
                />

                <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
                  <h3 className="uk-margin-remove">Sed consequat urna.</h3>
                  <p className="uk-margin-remove uk-visible@s">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </li>
              <li>
                <StaticImage
                  src="../images/leader9.jpeg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={1200}
                  height={550}
                />

                <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
                  <h3 className="uk-margin-remove">Sed consequat urna.</h3>
                  <p className="uk-margin-remove uk-visible@s">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </li>
              <li>
                <StaticImage
                  src="../images/Leadership10.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={1200}
                  height={550}
                />
                <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
                  <h3 className="uk-margin-remove">Sed consequat urna.</h3>
                  <p className="uk-margin-remove uk-visible@s">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </li>
            </ul>
            {/* <a className="uk-position-center-left uk-position-small" href="/" uk-slidenav-previous="true" uk-slidenav-previous="ratio: 1.5" uk-slideshow-item="previous">{null}</a>
            <a className="uk-position-center-right uk-position-small" href="/" uk-slidenav-next="true" uk-slidenav-next="ratio: 1.5" uk-slideshow-item="next">{null}</a> */}
            <div className="uk-hidden@m uk-light">
              <a
                className="uk-position-center-left uk-position-small"
                href="/"
                aria-label="Previous"
                uk-slidenav-previous="true"
                uk-slider-item="previous"
              >
                {null}
              </a>
              <a
                className="uk-position-center-right uk-position-small"
                href="/"
                aria-label="Next"
                uk-slidenav-next="true"
                uk-slider-item="next"
              >
                {null}
              </a>
            </div>
            <div className="uk-visible@m">
              <a
                className="uk-position-center-left-out uk-position-small"
                href="/"
                aria-label="Next"
                uk-slidenav-previous="true"
                uk-slider-item="previous"
              >
                {null}
              </a>
              <a
                className="uk-position-center-right-out uk-position-small"
                href="/"
                aria-label="Previous"
                uk-slidenav-next="true"
                uk-slider-item="next"
              >
                {null}
              </a>
            </div>
          </div>
        </div>
        {/* <!-- /large image --> */}

        {/* <!-- text --> */}
        <div className="uk-container uk-container-small">
          <p>
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae,
            ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam
            egestas semper. Aenean ultricies mi vitae est. Mauris placerat
            eleifend leo.
          </p>
          <blockquote cite="#">
            <p className="uk-margin-small-bottom">
              The blockquote element represents content that is quoted from
              another source, optionally with a citation which must be within a
              footer or cite element.
            </p>
            <footer>
              Someone famous in{" "}
              <cite>
                <a href="/" aria-label="Source Title">
                  Source Title
                </a>
              </cite>
            </footer>
          </blockquote>
          <h3>Aenean ultricies mi vitae est.</h3>
          <p>
            Nulla facilisi. Mauris euismod metus eu augue dictum luctus. Aliquam
            tellus mi, venenatis sit amet laoreet non, laoreet sit amet dolor.
            Mauris feugiat sem sit amet tortor tristique, eu varius risus
            accumsan. Nullam tincidunt erat sed turpis suscipit, in lacinia mi
            venenatis. Proin in dui sed neque malesuada dictum vitae vitae elit.
            I
          </p>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
            <li>Aliquam tincidunt mauris eu risus.</li>
            <li>Vestibulum auctor dapibus neque.</li>
          </ul>
          <p>
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae,
            ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam
            egestas semper. Aenean ultricies mi vitae est. Mauris placerat
            eleifend leo.
          </p>
        </div>
        {/* <!-- text --> */}

        {/* <!-- gallery --> */}
        <div className="uk-container uk-container-small uk-margin-medium uk-dark">
          <div
            className="uk-grid uk-grid-medium uk-child-width-1-2 uk-child-width-1-3@s"
            uk-grid="true"
            uk-lightbox="true"
          >
            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072" target="_blank" rel="noreferrer"> */}

                {/*  <img src="https://picsum.photos/300/200/?random=1" width="300" height="200" alt="" /> */}
                <StaticImage
                  src="../images/Leadership10.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-padding-small uk-text-center uk-text-muted">
                  Caption of the image
                </figcaption>
              </figure>
            </div>

            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072" target="_blank" rel="noreferrer"> */}

                {/* <img src="https://picsum.photos/300/200/?random=2" width="300" height="200" alt="" /> */}

                <StaticImage
                  src="../images/hands-1.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-text-small uk-text-muted uk-text-center uk-padding-small uk-visible@s">
                  Aenean vitae est
                </figcaption>
              </figure>
            </div>

            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072"> */}

                {/* <img src="https://picsum.photos/300/200/?random=3" width="300" height="200" alt=""  /> */}

                <StaticImage
                  src="../images/Leadership_Theories.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-text-small uk-text-muted uk-text-center uk-padding-small uk-visible@s">
                  Aenean vitae est
                </figcaption>
              </figure>
            </div>

            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072"> */}

                {/* <img src="https://picsum.photos/300/200/?random=3" width="300" height="200" alt=""  /> */}

                <StaticImage
                  src="../images/management1.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-text-small uk-text-muted uk-text-center uk-padding-small uk-visible@s">
                  Aenean vitae est
                </figcaption>
              </figure>
            </div>

            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072"> */}

                {/* <img src="https://picsum.photos/300/200/?random=3" width="300" height="200" alt=""  /> */}

                <StaticImage
                  src="../images/levels-of-management-mc-slide2.png"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-text-small uk-text-muted uk-text-center uk-padding-small uk-visible@s">
                  Aenean vitae est
                </figcaption>
              </figure>
            </div>

            <div>
              <figure>
                {/* <a href="https://picsum.photos/1200/800?image=1072"> */}

                {/* <img src="https://picsum.photos/300/200/?random=3" width="300" height="200" alt=""  /> */}

                <StaticImage
                  src="../images/management20.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  layout="constrained"
                  width={300}
                  height={200}
                />

                {/* </a> */}
                <figcaption className="uk-text-small uk-text-muted uk-text-center uk-padding-small uk-visible@s">
                  Aenean vitae est
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        {/* <!-- /gallery --> */}
      </section>

      <MoreStories />
    </>
  )
}

export default TestPage

export const query = graphql`
  {
    strapiNewsPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
